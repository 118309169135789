import React, { useState, useEffect } from 'react'
import canopyDemo from '../../video/canopyDemo.mp4'
import './center.css'

const Center = () => {
  const [email, setEmail] = useState('')
  const [error, setError] = useState(<div className="errorContainer" id="homeError" />)
  const [disabled, setDisabled] = useState (true)
  useEffect(() => {
    if(email === '') setDisabled(true)
    else setDisabled(false)
    setError(<div className="errorContainer" id="homeError"></div>)
  }, [email])
  const handleClick = () => {
    if(disabled) setError(<div className="errorContainer" id="homeError">Please enter your email</div>)
    else setError(<div className="errorContainer" id="homeError" />)
  }
  return (
    <div className="center">
      <h3>meet canopy</h3>
      <h1>The Browser for Business</h1>
      <div>
        <h2>
          Bring your favorite apps and people together to connect, integrate, and activate your business.
        </h2>
      </div>
      <form action="/success" name="join-waitlist" method="post" data-netlify="true" data-netlify-honeypot="bot-field" id="cta">
        <input type="hidden" name="form-name" value="join-waitlist" />
        <div style={{ display: 'flex' }}>
          <input 
            value={email} 
            autoFocus 
            autoComplete='on'
            type='email'
            name='email'
            onChange={(e) => setEmail(e.target.value)} 
            placeholder='Enter your email'
          />
          <button type={disabled ? "button" : "submit"} onClick={handleClick}>
            Join Waitlist
          </button>
        </div>
        {error}
      </form>
      <video src='https://trusting-elion-2e8d69.netlify.com/static/canopyDemo-69b0823156e1eb19e0dc8dcd425c0c79.mp4' playsInline autoPlay muted loop />
    </div>
  )
}

export default Center
